


































































import { Component } from 'vue-property-decorator';
import { readHasPhysicianAccess } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import LabRequisitionItem from '@/components/LabRequisitionItem.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchGetRequisitionsByState, dispatchUpdateRequisition } from '@/store/crud/actions';
import { IUserProfile } from '@/interfaces/users';
import { readRequisitions } from '@/store/crud/getters';
import { IConfirmRequisition, IRequisition } from '@/interfaces/requisitions';

@Component({
  components: {
    LabRequisitionItem,
  },
})
export default class LabRequisition extends AppComponent {
  public userProfile: IUserProfile | null = null;
  public userRequisitions: IRequisition[] = [];
  public isPhisician: boolean | null = true;
  public tab = 0;
  public confirmButtonDisabled = false;

  /**
   * Created hook, calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /*
    creates a patient tab name based on first name and last name
  */
  public getPatientTabName(item) {
    if (item && item.patient && item.patient.fname && item.patient.lname) {
      // if there is first name and last name
      return item.patient.fname[0].toUpperCase() + '.' + item.patient.lname;
    } else if (
      item &&
      item.patient &&
      !item.patient.fname &&
      item.patient.lname
    ) {
      // only last name
      return item.patient.lname;
    } else if (
      item &&
      item.patient &&
      item.patient.fname &&
      !item.patient.lname
    ) {
      // only first name
      return item.patient.fname;
    } else if (
      item &&
      item.patient &&
      !item.patient.fname &&
      !item.patient.lname &&
      item.patient.email
    ) {
      // no names but email
      return item.patient.email;
    } else {
      // no names and no email
      return 'Patient';
    }
  }

  /*
    It approves the current requisition and re-configure the screen
    by calling the initial configureScreen, this will clear all the
    screen data and re-draw
  */
  public async confirm() {
    if (!this.requisitionOnView || !this.requisitionOnView.id) { return; }

    if (this.userProfile && this.userProfile.id && this.userProfile.isPhysician) {
      const confirmRequisition: IConfirmRequisition = {
        confirmation: true,
        referringPhysicianId: this.userProfile?.id!,
      };

      this.confirmButtonDisabled = true;

      const result = await dispatchUpdateRequisition(this.$store, {
        requisitionId: this.requisitionOnView!.id,
        requisition: confirmRequisition,
      });

      if (result) {
        this.toast('requisition approved', false);
        this.fetchData();
      } else {
        this.toast('error while approving requisition', true);
      }

      this.confirmButtonDisabled = false;

    } else {
      // FIXME - important log here
      this.toast('Unable to approve requisition', true);
    }
  }

  /**
   * fetches the data needed to build the view from store/server
   * it also displays the loading screen
   */
  private async fetchData() {
    this.setAppLoading(true);
    this.userProfile = await readUserProfile(this.$store);
    this.isPhisician = await readHasPhysicianAccess(this.$store);

    if (this.isPhisician && this.userProfile && this.userProfile.id) {
      await dispatchGetRequisitionsByState(this.$store);
      this.userRequisitions = readRequisitions(this.$store);
    } else {
      // user is not available or not phisician
      this.$router.back();
    }
    this.setAppLoading(false);
  }

  /**
   * gets the current requisition on view based on the tab index
   */
  get requisitionOnView() {
    if (this.userRequisitions && this.userRequisitions.length > 0) {
      return this.userRequisitions[this.tab];
    }
  }
}
